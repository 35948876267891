import axios from 'axios';

import {
  ArticleAction,
  ArticleActionType,
  ArticleDispatch,
  ArticleThunk
} from './types';

function articleRequest(): ArticleAction {
  return { type: ArticleActionType.REQUEST };
}

function articleSuccess(payload: string): ArticleAction {
  return { type: ArticleActionType.SUCCESS, payload };
}

function articleFailure(payload: string): ArticleAction {
  return { type: ArticleActionType.FAILURE, payload };
}

export function loadArticle(id: string): ArticleThunk {
  return function (dispatch: ArticleDispatch): void {
    dispatch(articleRequest());

    try {
      const url = [process.env.PUBLIC_URL, 'articles', `${id}.md`].join('/');
      axios({ url, timeout: 5000 })
      .then(res => dispatch(articleSuccess(res.data)))
      .catch(error => dispatch(articleFailure(error)));
    } catch (error: any) {
      dispatch(articleFailure(error));
    }
  };
}
