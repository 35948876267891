import { version } from '../../../package.json';

export function Footer(): JSX.Element {
  const year = new Date().getFullYear();

  return (
    <div id="Footer" style={{backgroundColor: '#00273f'}}>
      <div className="container-fluid py-3">
        <div className="row text-center">
          <div className="col-12" style={{color: '#829eb0'}}>
            © {year} mappei.hu
          </div>
          <div className="col-12 d-none">
            v{version}
          </div>
        </div>
      </div>
    </div>
  );
}
