import { FAIconProps, FAIconLg } from './FA';

const SOCIALS: SocialProps[] = [
  {
    textAlign: 'right',
    platform: 'Facebook',
    href: 'https://www.facebook.com/mappei',
    icon: 'fa-facebook-f',
    color: '#3a589b'
  },
  {
    textAlign: 'center',
    platform: 'YouTube',
    href: 'https://www.youtube.com/user/MappeiSiegen',
    icon: 'fa-youtube-play',
    color: '#cc181e'
  },
  {
    textAlign: 'left',
    platform: 'Instagram',
    href: 'https://www.instagram.com/mappei.organisation',
    icon: 'fa-instagram',
    color: '#517fa6'
  }
];

export function Socials(): JSX.Element {
  const socials = SOCIALS.map((s, i) => (<Social key={i} {...s}/>));

  return (
    <div id="Socials" style={{backgroundColor: '#a2b8c2'}} className="d-none d-sm-block">
      <div id="Socials" className="container py-2 text-white">
        <div className="row">
          {socials}
        </div>
      </div>
    </div>
  );
}


type SocialProps = {
  textAlign: string;
  href: string;
  platform: string;
} & FAIconProps;

function Social({ textAlign, href, platform, ...props }: SocialProps): JSX.Element {
  return (
    <div className={`col-4 text-${textAlign}`}>
      <a href={href} target="_blank" rel="noreferrer" style={{color: '#ffffff', textDecoration: 'none'}}>
        <FAIconLg {...props} />
        <b>{platform}</b>
      </a>
    </div>
  );
}
