import { Img } from "./Img";

export function Header(): JSX.Element {
  return (
    <div
      className="container mb-3"
      style={{
        marginTop: '70px',
        textTransform: 'uppercase'
      }}
    >
      <div className="row align-items-center">
        <div className="col-12 col-lg-9 text-center text-lg-left">
          <h1 className="py-0 my-4 my-lg-3 font-weight-bold">
            A munkamódszer a lényeg!
          </h1>
        </div>

        <div className="col-12 col-lg-3 text-right d-none d-lg-inline">
          <Img src="images/logo.png" alt="Logo" />
        </div>
      </div>
    </div>
  );
}
