import { Action } from 'redux';

export type Article = {
  loading: boolean;
  title: string;
  payload: any;
  error: string;
};

export enum ArticleActionType {
  REQUEST = 'articleRequest',
  SUCCESS = 'articleSuccess',
  FAILURE = 'articleFailure'
}

export type ArticleAction = Action<ArticleActionType> & {
  payload?: any;
};

export type ArticleDispatch = (action: ArticleAction) => void;
export type ArticleThunk = (dispatch: ArticleDispatch) => void;
