import { Link } from "react-router-dom";

const INITIAL = [
  { id: 'osszes', children: 'Összes termék' },
  { id: 'indulo-keszlet', children: 'Induló készlet' },
  { id: 'tabcolor', children: 'TABCOLOR' },
  { id: 'mappa', children: 'Mappa' },
  { id: 'etikett', children: 'Etikett' },
  { id: 'doboz', children: 'Doboz' },
  { id: 'kiegeszitok', children: 'Kiegészítők' },
  { id: 'butor', children: 'Bútor' }
];

type CategoriesProps = {
  select?: string;
  shadow?: boolean;
};

export function Categories({ select, shadow }: CategoriesProps): JSX.Element {
  const categories = INITIAL.map((c, i) => (
    <Category key={i} {...c} selected={select === c.id} />
  ));

  return (
    <div className={`row py-3 my-0 bg-white ${shadow ? 'shadow-sm' : ''}`}>
      <div className="col-12 text-center">
        {categories}
      </div>
    </div>
  );
}

type CategoryProps = {
  id: string;
  children: string;
  selected?: boolean;
};

function Category({ id, children, selected }: CategoryProps): JSX.Element {
  return (
    <Link
      className={`btn btn-lg m-1 ${selected ? 'btn-warning': 'btn-danger'}`}
      to={`/termekek/${id}`}
    >
      {children}
    </Link>
  );
}
