import { HomeProductType } from "../../../store";
import { Img } from "../Img";

import './ProductCard.css';

type ProductCardProps = {
  product: HomeProductType;
  className?: string;
};

export function ProductCard({ className = 'col-12 col-lg-6 col-xl-4', product }: ProductCardProps): JSX.Element {
  return (
    <div className={`${className} my-3 product`}>
      <div className="name">
        <span>{product.name}</span>
      </div>

      <div className="description">
        {product.description}
      </div>

      <div className="content">
        <Img src={product.image} alt={product.name} className="img-fluid"/>

        <a href={product.url} download={product.label ? 'mappei_szinskala.pdf' : undefined}>
          <Caret />
          {product.label ? product.label : 'További információ a termékről'}
        </a>

        {!product.label && (
          <>
            <br />

            <a href={`/termekek/${product.category}`}>
              <Caret />
              Termékek
            </a>
          </>
        )}
      </div>
    </div>
  );
}

function Caret(): JSX.Element {
  return (
    <span className="fa-stack fa-sm mr-1">
      <i className="fa fa-square fa-stack-2x"></i>
      <i className="fa fa-caret-right fa-stack-1x fa-inverse"></i>
    </span>
  );
}
