import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import article from './article/reducer';
import cards from './cards/reducer';
import carousel from './carousel/reducer';
import home from './home/reducer';
import products from './products/reducer';

const reducer = combineReducers({
  article,
  cards,
  carousel,
  home,
  products
});

export * from './article';
export * from './cards';
export * from './carousel';
export * from './home';
export * from './products';
export type Store = ReturnType<typeof reducer>;
export const store = createStore(reducer, applyMiddleware(thunk));
