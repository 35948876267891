import jQuery from 'jquery';
import { useCallback, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Img } from './Img';

const style = {
  backgroundColor: '#ffffff',
  borderBottom: '1px solid #aaaaaa',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)'
};

export function Nav(): JSX.Element {
  const onClick = useCallback((event) => {
    event.preventDefault();

    if (!window) return;

    window.scrollTo({ top: Number.MAX_SAFE_INTEGER });
  }, []);

  useEffect(() => {
    const toggler = jQuery('.navbar-toggler');
    const navbar = jQuery('.navbar-collapse');

    jQuery('nav a').on('click', (event: Event) => {
      if (toggler.css('display') !== 'none') {
        if (navbar.css('display') !== 'none') {
          toggler.trigger('click');
        }
      }
    });
  }, []);

  return (
    <nav id="Nav" className="navbar fixed-top navbar-expand-lg navbar-light" style={style}>
      <div className="container px-0">
        <NavbarBrand />
        <NavbarToggler />

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="nav navbar-nav ml-auto">
            <NavItem to="/" name="Kedzőlap"/>
            <NavItem to="/cikk/a-mappei-modszer" name="A MAPPEI-módszer"/>
            <NavItem to="/cikk/a-tabcolor-rendszer" name="A TABCOLOR-rendszer"/>
            <NavItem to="/cikk/a-kaizen-iroda" name="A KAIZEN-iroda"/>
            <NavItem to="/termekek" name="Termékek"/>
            <NavItem onClick={(e) => onClick(e)} name="Kapcsolat"/>
          </ul>
        </div>
      </div>
    </nav>
  );
}

function NavbarBrand(): JSX.Element {
  return (
    <NavLink
      to="/"
      className="navbar-brand py-0"
/*      style={{
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '22pt',
        fontWeight: 800,
        color: '#043c60',
        letterSpacing: '-2pt'
      }}*/
    >
      <Img src="mappei-logo.svg" alt="Mappei Logo" style={{height: 24}} />
    </NavLink>
  );
}

function NavbarToggler(): JSX.Element {
  return (
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      style={{
        backgroundColor: '#043c60'
      }}
    >
      <i className="fa fa-bars fa-lg text-white p-2" />
    </button>
  );
}

type NavItemProps = {
  to?: string;
  onClick?: (e: any) => void;
  name: string;
};

function NavItem({ to, onClick, name }: NavItemProps): JSX.Element {
  return (
    <li className="nav-item">
      <NavLink
        to={to || '#'}
        onClick={onClick}
        className="nav-link px-0 ml-3"
        style={{
          fontSize: '12pt',
          fontWeight: 'bold',
          color: '#777777',
        }}
      >
        {name}
      </NavLink>
    </li>
  );
}
