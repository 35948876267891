import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import {
  Article,
  Home,
  Products,
  PageNotFound,
  Tabcolor
} from './routes';

export function Application() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Redirect exact path="/cikk" to="/" />
        <Route exact path="/cikk/a-tabcolor-rendszer" component={Tabcolor} />
        <Route path="/cikk/:id" component={Article} />
        <Redirect exact path="/termekek" to="/termekek/osszes/1" />
        <Redirect exact path="/termekek/:category" to="/termekek/:category/1" />
        <Route exact path="/termekek/:category/:page" component={Products} />
        <Route path="**" component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}
