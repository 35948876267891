import { Article, ArticleAction, ArticleActionType } from "./types";

const initialState: Article = {
  loading: false,
  title: '',
  payload: undefined,
  error: ''
};

export default function reducer(state: Article = initialState, { type, payload }: ArticleAction): Article {
  switch (type) {
    case ArticleActionType.REQUEST: {
      return {
        loading: true,
        title: '',
        payload: undefined,
        error: ''
      };
    }

    case ArticleActionType.SUCCESS: {
      return {
        loading: false,
        error: '',
        ...parsePayload(payload)
      };
    }

    case ArticleActionType.FAILURE: {
      return {
        loading: false,
        title: '',
        payload: undefined,
        error: payload
      };
    }

    default: {
      return state;
    }
  }
}

function parsePayload(payload: string): { title: string, payload: any} {
  const split = payload.split('\n');
  return {
    title: split.shift() || '',
    payload: split.join('\n')
  };
}
