import { useSelector } from 'react-redux';

import { Store } from '../../../store';
import { BaseLayout } from '../../layouts';
import { ProductCard } from '../../shared';
import { Carousel } from './Carousel';

export function Home(): JSX.Element {
  const products = useSelector((store: Store) => store.home).slice(1).map((p, i) => (
    <ProductCard key={i} product={p} />
  ));

  return (
    <BaseLayout header={<Carousel />}>
      <h3 className="my-4">Üdvözöljük a MAPPEI honlapján!</h3>
      <p>A számítógép térhódítása ellenére a papírmentes iroda - egyelőre - utópia. A vállalatok sok területen törekednek ugyan a papír irodából történő kiszorítására, ennek megvalósulása azonban még várat magára. Az íróasztalt beborító irathalmok, az éppen szükséges dokumentumok állandó keresgélése,a párhuzamosan használt többféle iratkezelési eszköz gátolja a hatékony irodai munkavégzést. A dolgozók a nem megfelelő dokumentum management következtében rengeteg időt - éves szinten akár 30 napot is - pazarolnak iratok, dokumentumok keresésére.</p>
      <p>Ennek nem feltétlenül kell így lennie! A német <b>MAPPEI-módszer</b> segítségével racionalizálhatja az irodai munkát. Az általunk ajánlott módszer és rendszer segítségével áttekinthető struktúrákat alakíthat ki iratkezelésében. Az iratok keresése megszűnik, az aktahegyek eltűnnek az íróasztalról, rend és tisztaság alakul ki munkakörnyezetében. Ennek köszönhetően időt takarít meg, helyet nyer, mentesül az iratkeresések okozta stressz alól.</p>
      <p><b>A munkamódszer a lényeg!</b></p>

      <div className="row">
        {products}
      </div>
    </BaseLayout>
  );
}
