import axios from 'axios';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { NavLink } from 'react-router-dom';
import { ProductsType, ProductState } from '../../../store';
import { Img } from '../../shared';

function parse(payload: string): ProductViewProps {
  const data = payload.split('-----').map(s => s.trim());
  return {
    name: data[0],
    image: data[1],
    description: data[2],
    price: data[3],
    unit: data[4],
    pid: data[5],
    url: data[6]
  };
}

type ProductProps = {
  product: ProductsType;
};

export function Product({ product }: ProductProps): JSX.Element {
  const [p, setP] = useState<ProductState>({ loading: false, payload: undefined, error: '' });

  useEffect(() => {
    setP({ loading: true, payload: undefined, error: '' });

    axios({ url: [process.env.PUBLIC_URL, 'products', `${product.pid}.md`].join('/'), timeout: 5000 })
      .then(res => setP({ loading: false, payload: parse(res.data), error: '' }))
      .catch(error => setP({ loading: false, payload: undefined, error }))
  }, [product, setP]);

  if (!p || p.loading || !p.payload) {
    return (
      <div className="col-12 col-md-6 col-lg-4 text-center">
        <div className="spinner-border text-danger" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return <ProductView { ...p.payload }/>;
}

type ProductViewProps = {
  name: string;
  image: string;
  description: string;
  price: string;
  unit: string;
  pid: string;
  url: string;
};

function ProductView({ name, image, description, price, unit, pid, url }: ProductViewProps): JSX.Element {
  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div className="card">
        <h5
          className="card-title font-weight-bold mx-3 my-2"
          style={{
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {name}
        </h5>

        <div className="border-top border-bottom bg-light d-inline-flex align-items-center" style={{height: 250}}>
          <Img className="p-3 mx-auto" src={image} alt={name} style={{maxHeight: 220, maxWidth: 260 }} />
        </div>

        <div className="card-body">
          <p className="card-text">
            <b>Ár: {price}</b><br />
            Csomagolási egység: {unit}<br />
            Cikkszám: {pid}
          </p>
        </div>

        <div className="hover bg-light w-100 h-100 rounded text-left d-flex flex-column">
          <h5 className="card-title font-weight-bold mx-3 my-2">
            {name}
          </h5>

          <ReactMarkdown
            className="px-3 height">
            {description}
          </ReactMarkdown>

          {(url !== '/cikk/0') && (
            <div className="m-3 mt-auto">
              <NavLink to={url} className="btn btn-success w-100">
                További információ a termékről
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
