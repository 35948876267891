import { ButtonHTMLAttributes, InputHTMLAttributes, LiHTMLAttributes, TextareaHTMLAttributes } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const validationSchema = Yup.object({
  name: Yup.string().required('Nem adta meg a nevét!'),
  phone: Yup.string().required('Nem adta meg a telefonszámát!'),
  email: Yup.string().email('Hibás e-mail cím formátum!').required('Nem adta meg az e-mail címét!'),
  message: Yup.string().required('Nem adta meg az üzenetet!')
});

export function Messanger(): JSX.Element {
  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors
  } = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      message: ''
    },
    validationSchema,
    onSubmit: (values, actions) => {
      const formData = new FormData();
      Object.entries(values).forEach(([k, v]) => {
        formData.append(k, v);
      });

      axios.post(
        "https://getform.io/f/856d52eb-35ef-41c9-8f1b-74f372587f4b",
        formData
      )
      .then(res => {
        console.log(res);
        actions.resetForm();
        return res;
      })
      .catch(error => console.log(error));
    }
  });

  return (
    <div className="col-12 col-lg-6 my-3 px-4">
      <h3>Üzenjen nekünk:</h3>

      {(errors.name || errors.phone || errors.email || errors.message) && (
        <ul className="m-0 p-0 ml-3 my-2">
          {touched.name && errors.name && <Li>{errors.name}</Li>}
          {touched.phone && errors.phone && <Li>{errors.phone}</Li>}
          {touched.email && errors.email && <Li>{errors.email}</Li>}
          {touched.message && errors.message && <Li>{errors.message}</Li>}
        </ul>
      )}
      <form onSubmit={handleSubmit}>
        <Input
          name="name"
          type="text"
          placeholder="Név"
          onChange={handleChange}
          value={values.name}
        />

        <Input
          name="phone"
          type="tel"
          placeholder="Telefon"
          onChange={handleChange}
          value={values.phone}
        />

        <Input
          name="email"
          type="email"
          placeholder="E-mail cím"
          onChange={handleChange}
          value={values.email}
        />

        <Textarea
          name="message"
          placeholder="Üzenet"
          onChange={handleChange}
          value={values.message}
        ></Textarea>

        <Button type="submit">Elküldés</Button>
      </form>
    </div>
  );
}

function Li(props: LiHTMLAttributes<{}>): JSX.Element {
  return <li {...props} className="alert alert-warning text-danger px-2 py-1 my-2" />;
}

function Input(props: InputHTMLAttributes<{}>): JSX.Element {
  return <input
    {...props}
    id={props.name}
    className="form-control my-2"
    style={{
      color: '#222222',
      fontWeight: 'bold'
    }}
  />;
}

function Textarea(props: TextareaHTMLAttributes<{}>): JSX.Element {
  return <textarea
    {...props}
    className="form-control my-2"
    cols={30}
    rows={9}
    style={{
      color: '#222222',
      fontWeight: 'bold',
      resize: 'none'
    }}
  />
}

function Button(props: ButtonHTMLAttributes<{}>): JSX.Element {
  return <button
    {...props}
    className="form-control btn btn-danger"
    style={{
      fontWeight: 'bold'
    }}
  />;
}
