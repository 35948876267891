export type FAIconProps = {
  icon: string;
  color: string;
};

export function FAIcon({ icon, color }: FAIconProps): JSX.Element {
  return (
    <span className="fa-stack fa-md">
      <i className="fa fa-circle fa-stack-2x" style={{color}} />
      <i className={`fa ${icon} fa-stack-1x fa-inverse`} />
    </span>
  );
}

export function FAIconLg({ icon, color }: FAIconProps): JSX.Element {
  return (
    <span className="fa-stack fa-lg">
      <i className="fa fa-circle fa-stack-2x" style={{color}} />
      <i className={`fa ${icon} fa-stack-1x fa-inverse`} />
    </span>
  );
}
