import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Store } from '../../../store';
import { PageLayout } from '../../layouts';
import { Categories } from './Categories';
import { Pagination } from './Pagination';

import './Product.css';
import { Product } from './Product';

const ITEM_PER_PAGE = 24;

export function Products(): JSX.Element {
  const { category, page } = useParams<{ category: string, page: string }>();
  const p = parseInt(page) - 1;

  const PRODUCTS = useSelector((store: Store) => store.products);
  const start = p * ITEM_PER_PAGE;
  const end = start + ITEM_PER_PAGE;

  const filtered = PRODUCTS.filter(p => {
    if (category === 'osszes') return true;

    return p.category === category;
  });

  const products = filtered.slice(start, end).map((p, i) => (<Product key={i} product={p} />));
  const numPages = Math.ceil(filtered.length / ITEM_PER_PAGE);

  return (
    <PageLayout title="Termékek" bg="bg-transparent" shadow="">
      <Categories select={category} shadow />
      <div className="row mt-4">{products}</div>
      <Pagination category={category} current={p + 1} max={numPages} />
    </PageLayout>
  );
}
