import { ReactNode, useEffect } from 'react';
import { Nav, Socials, Contact, Footer, Header } from '../shared';

type BaseLayoutProps = {
  header?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
  bg?: string;
  shadow?: string;
};

export function BaseLayout({ header, children, bg = 'bg-white', shadow = 'shadow-sm' }: BaseLayoutProps): JSX.Element {
  return (
    <>
      <Nav />
      <Header />

      <main id="Main" className="text-justify">
        <div className={`container ${bg} p-0 mb-4 ${shadow}`}>
          <div className="row m-0 p-0">
            <PageHeader>{header}</PageHeader>
            <Page>{children}</Page>
          </div>
        </div>
      </main>

      <Socials />
      <Contact />
      <Footer />
    </>
  );
}

type PageHeaderProps = {
  children?: ReactNode | ReactNode[];
};

function PageHeader({ children }: PageHeaderProps): JSX.Element {
  return (
    <div className="w-100 bg-white" style={{paddingBottom: '2px', borderBottom: '3px solid red'}}>
      <div className="col-12 m-0 p-0" style={{color: '#ffffff', backgroundColor: '#043c60', minHeight: '6px'}}>
        {children}
      </div>
    </div>
  );
}

type PageProps = {
  children?: ReactNode | ReactNode[];
};

function Page({ children }: PageProps): JSX.Element {
  useEffect(() => {
    if (!window) return;

    window.scrollTo({top: 0});
  });

  return (
    <div
      className="col-12 m-0 px-3 pb-3"
      style={{minHeight: '50vh'}}
    >
      {children}
    </div>
  );
}
