import { HomeState } from "./types";

export const INITIAL: HomeState = [
  {
    name: "Színskála",
    description: "",
    image: "home/0.jpg",
    url: "/documents/mappei_szinskala.pdf",
    category: "",
    label: "Letöltés"
  },
  {
    name: "Etikettek irati feliratozására",
    description: "Tökéletes áttekintés megteremtésének eszköze",
    image: "home/1.jpg",
    url: "/cikk/feliratozas",
    category: "etikett"
  },
  {
    name: "Rendszerező mappa",
    description: "Egyéni igényeihez igazodó mappatípusok",
    image: "home/2.jpg",
    url: "/cikk/mappak",
    category: "mappa"
  },
  {
    name: "MAPPEI - FLEX/NOTES",
    description: "A kreatív prezentáció rugalmas eszköze",
    image: "home/3.jpg",
    url: "/cikk/flex-notes",
    category: "etikett"
  },
  {
    name: "TABCOLOR",
    description: "Nagy mennyiségű irat szisztematikus rendszerezése",
    image: "home/4.jpg",
    url: "/cikk/a-tabcolor-rendszer",
    category: "tabcolor"
  },
  {
    name: "Irattároló dobozok",
    description: "Iratai stabil megtámasztására",
    image: "home/5.jpg",
    url: "/cikk/dobozok",
    category: "doboz"
  },
  {
    name: "Bútorok",
    description: "MAPPEI bútorok - áttekinthető, rugalmas és mobilis",
    image: "home/6.jpg",
    url: "/cikk/butorok",
    category: "butor"
  }
];
