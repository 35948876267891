import { useSelector } from 'react-redux';

import { Store, CardType } from '../../../store';
import { FAIcon, Img } from '..';

export function Cards(): JSX.Element {
  const CARDS = useSelector((store: Store) => store.cards);
  const cards = CARDS.map((s, i) => (<Card key={i} {...s} className={i === 0 ? 'd-block' : undefined} />));

  return (
    <div className="col-12 col-lg-6 my-2">
      {cards}
    </div>
  );
}

type CardProps = CardType & {
  name?: string;
  flag: string;
  country: string;
  phone: string;
  email: string;
  className?: string;
};

function Card({ name, phone, email, className = 'd-none d-lg-block', ...props }: CardProps): JSX.Element {
  return (
    <div className={`w-100 my-2 p-3 ${className}`} style={{backgroundColor: '#295978'}}>
      {name && <Name name={name} />}
      <Country {...props} />
      <Phone phone={phone} />
      <Email email={email} />
    </div>
  );
}

type NameProps = {
  name: string;
};

function Name({ name }: NameProps): JSX.Element {
  return (
    <h3>
      <FAIcon icon="fa-user" color="#043c60" />
      <span className="ml-2">{name}</span>
    </h3>
  );
}

type CountryProps = {
  country: string;
} & FlagProps;

function Country({ flag, country }: CountryProps): JSX.Element {
  return (
    <h5>
      <Flag flag={flag} />
      <span className="ml-2">{country}</span>
    </h5>
  );
}

type FlagProps = {
  flag: string;
};

function Flag({ flag }: FlagProps): JSX.Element {
  return <Img src={`images/flags/flag-${flag}.jpg`} alt={flag} />;
}

type PhoneProps = {
  phone: string;
};

function Phone({ phone }: PhoneProps): JSX.Element {
  return (
    <div>
      <FAIcon icon="fa-phone" color="#043c60" />
      <span className="ml-1">{phone}</span>
    </div>
  );
}

type EmailProps = {
  email: string;
};

function Email({ email }: EmailProps): JSX.Element {
  return (
    <div>
      <FAIcon icon="fa-envelope" color="#043c60" />
      <a
        className="ml-1"
        href={`mailto:${email}`}
        style={{color: '#ffffff', textDecoration: 'underline'}}
      >
        {email}
      </a>
    </div>
  );
}
