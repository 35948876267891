import { Redirect } from 'react-router';
import ReactMarkdown from 'react-markdown';

import { useArticle, useProducts } from '../../hooks';
import { PageLayout } from '../../layouts';
import { ProductCard } from '../../shared';

import './Article.css';

export function Article(): JSX.Element {
  const [id, article] = useArticle();
  const products = useProducts(id).map((p, i) => (
    <ProductCard key={i} product={p} className="col-12" />
  ));

  if (article.error !== '') {
    return <Redirect to={`/hiba/${id}`} />;
  }

  return (
    <PageLayout title={article.title}>
      <div className="row pt-4">
        <div className="col-12 col-xl-8">
          <ReactMarkdown className="article">
            {article.payload}
          </ReactMarkdown>
        </div>
        <div className="col-4 d-none d-xl-inline">
          <div className="row">
            {products}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
