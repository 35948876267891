import { Cards } from './Cards';
import { Messanger } from './Messanger';

export function Contact(): JSX.Element {
  return (
    <div id="Contact" style={{backgroundColor: '#043c60', color: '#ffffff'}}>
      <div className="container px-0">
        <div className="row">
          <Cards />
          <Messanger />
        </div>
      </div>
    </div>
  );
}
