import { NavLink } from "react-router-dom";
import { PageLayout } from "../layouts";

export function PageNotFound(): JSX.Element {
  return (
    <PageLayout title="Az oldal nem található!">
      <div className="col-12 my-5 text-center">
        <h2>Sajnáljuk, a keresett oldal nem található!</h2>
        <NavLink to="/" className="btn btn-danger btn-lg mt-5">Vissza a kezdőoldalra</NavLink>
      </div>
    </PageLayout>
  );
}
