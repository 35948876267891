import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';

import { CarouselType, Store } from "../../../store";
import { Img } from "../../shared";

export function Carousel(): JSX.Element {
  const ITEMS = useSelector((store: Store) => store.carousel);
  const items = ITEMS.map((c, i) => (<CarouselItem key={i} {...c} active={i === 0} />));

  return (
    <div id="carouselExampleSlidesOnly" className="carousel slide d-none d-lg-block" data-ride="carousel">
      <div className="carousel-inner">{items}</div>
    </div>
  );
}

type CarouselItemProps = CarouselType & {
  active?: boolean;
};

function CarouselItem({ title, content, to, src, active = false }: CarouselItemProps): JSX.Element {
  const className = 'm-0 p-0 border-0';

  return (
    <div className={`carousel-item p-0 ${active ? 'active' : ''}`}>
      <table className={`table text-left ${className}`}>
        <tbody className={className}>
          <tr className={className}>
            <td className="m-0 p-3 border-0 w-100">
              <div style={{height: 270}}>
                <h2>{title}</h2>
                <p>{content}</p>
              </div>

              <NavLink to={to} className="btn btn-light btn-lg bg-white d-block">
                <i className="fa fa-caret-right mr-2" />
                További információk
              </NavLink>
            </td>

            <td className={className}>
              <Img className={className} src={src} height="350" alt="Carousel" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
