import { NavLink } from "react-router-dom";

type PaginationProps = {
  category: string;
  current: number;
  max: number;
};

export function Pagination({ category, current, max }: PaginationProps): JSX.Element {
  const items = getElements(category, current, max);

  return (
    <div className="row mt-5">
      <div className="col-12">
        <nav arial-label="Page navigation">
          <ul className="pagination justify-content-center">
            {items}
          </ul>
        </nav>
      </div>
    </div>
  );
}

const values = ['<<', '<', '', '>', '>>'];
function getElements(category: string, current: number, max: number): JSX.Element[] {
  const pages: (number | string)[] = [-current, -1, 0, 1, -current+max].map(
    (v) => Math.max(1, Math.min(max, v + current))
  );

  const elements: JSX.Element[] = [];

  pages.forEach((v, i) => {
    const value = i === 2 ? v.toString() : values[i];

    elements.push(
      <PageItem
        key={i}
        to={`/termekek/${category}/${v}`}
        value={i === 2 ? `${value} / ${max}` : value}
        active={i === 2}
      />
    );
  });
/*
  for (let i = 1; i <= max; ++i) {
    elements.push(
      <PageItem
        key={i}
        to={`/termekek/${category}/${i}`}
        value={i.toString()}
        active={i === current}
      />
    );
  }
*/
  return elements;
}

type PageItemProps = {
  to: string;
  value: string;
  active?: boolean;
};

function PageItem({ to, value, active }: PageItemProps): JSX.Element {
  return (
    <li className={`page-item ${active ? 'active' : ''}`}>
      <NavLink
        to={to}
        className="page-link"
        style={{width: 80, textAlign: 'center'}}
      >
        {value}
      </NavLink>
    </li>
  );
}
