import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Article, loadArticle, Store } from '../../store';

export function useArticle(): [string, Article] {
  const { id } = useParams<{id: string}>();
  return useArticleById(id);
}

export function useArticleById(id: string): [string, Article] {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadArticle(id));
  }, [id, dispatch]);

  return [id, useSelector((store: Store) => store.article)];
}
