import { Redirect } from "react-router";
import ReactMarkdown from "react-markdown";

import { useArticleById } from "../hooks";
import { PageLayout } from '../layouts';
import { Img } from "../shared";

export function Tabcolor(): JSX.Element {
  const [id, article] = useArticleById('a-tabcolor-rendszer');

  if (article.error !== '') {
    return <Redirect to={`/hiba/${id}`} />;
  }

  return (
    <PageLayout title={article.title}>
      <div className="row mt-4">
        <div className="col-12 col-xl-8">
          <ReactMarkdown className="article">
            {article.payload}
          </ReactMarkdown>
        </div>
        <div className="col-4 d-none d-xl-inline">
          <div className="row px-3 text-left">
            <div className="col-12 mb-4 p-3 shadow rounded border bg-white d-none">
              <h4 className="m-0 p-0 mb-3">Példa a téves lefűzésre</h4>
              {/*<img src="https://www.mappei.de/de/fileadmin/img/Inhalte%20Unterseiten/tabcolor/mappei-tabcolor-falschablage.jpg" alt="TABCOLOR" className="img-fluid rounded" />*/}
            </div>

            <div className="col-12 mb-4 p-3 shadow rounded border bg-white">
              <h4 className="m-0 p-0 mb-3">Példa a kivett akta helyét jelölő OUT-mappa használatára</h4>
              <Img src="articles/a-tabcolor-rendszer-4.jpg" alt="TABCOLOR" className="img-fluid rounded" />
            </div>

            <Img src="articles/a-tabcolor-rendszer-2.jpg" alt="TABCOLOR" />
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
