import { ReactNode } from "react";
import { BaseLayout } from "./BaseLayout";

type PageLayoutProps = {
  title: string;
  children?: ReactNode | ReactNode[];
  bg?: string;
  shadow?: string;
};

export function PageLayout({ title, children, ...props }: PageLayoutProps): JSX.Element {
  return (
    <BaseLayout header={<Title title={title} />} {...props}>
      {children}
    </BaseLayout>
  );
}

type TitleProps = {
  title: string;
};

function Title({ title }: TitleProps): JSX.Element {
  return <h4 className="m-0 p-0 p-3 text-white">{title}</h4>;
}
