import 'jquery';
import 'popper.js';
import 'bootstrap';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';

import './index.css';
import { store } from './store';
import { Application } from './components';

render(
  <StrictMode>
    <Provider store={store}>
      <Application />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
