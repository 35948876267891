import { CarouselState } from "./types";

export const INITIAL: CarouselState = [
  {
    title: 'Szeretne megszabadulni a papírmunka okozta stressztől?',
    content: 'Alkalmazza bátran a MAPPEI-rendszert és szabaduljon meg az iratkezelés okozta stressztől.',
    to: '/cikk/elonyok',
    src: 'articles/index.jpg'
  },
  {
    title: 'A MAPPEI-módszer',
    content: 'Rendszerezze iratait hatékonyan a német MAPPEI-módszer segítségével.',
    to: '/cikk/a-mappei-modszer',
    src: 'articles/a-mappei-modszer.jpg'
  },
  {
    title: 'A TABCOLOR-rendszer',
    content: 'Az amerikai TABCOLOR-rendszer optimális megoldás nagy mennyiségű irat rendszerezésére.',
    to: '/cikk/a-tabcolor-rendszer',
    src: 'articles/a-tabcolor-rendszer.jpg'
  },
  {
    title: 'Iratszerkezet',
    content: 'Hatékonyabb iratkezelés átlátható és ésszerű szabályok segítségével.',
    to: '/cikk/iratszerkezeti-terv',
    src: 'articles/iratszerkezeti-terv.jpg'
  },
  {
    title: 'A KAIZEN-iroda',
    content: 'Alkalmazza a Kaizen-módszert dokumentum managementjében: ne többet, hatékonyabban dolgozzon!',
    to: '/cikk/a-kaizen-iroda',
    src: 'articles/a-kaizen-iroda.jpg'
  }
];
