import { CardState } from "./types";

export const INITIAL: CardState = [
  {
    name: 'Fogti Márta',
    flag: 'hu',
    country: 'Magyarország',
    phone: '+36 70 313 37 91',
    email: 'info@mappei.hu'
  },
  {
    country: 'Németország',
    flag: 'de',
    phone: '+49 (0) 271/23 88-0',
    email: 'info@mappei.de'
  },
  {
    country: 'Ausztria',
    flag: 'at',
    phone: '+43 (0) 5287/86 27-0',
    email: 'info@mappei.at'
  }
];
